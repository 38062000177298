import {constants} from "../../config/constants";
import axios from "axios";
import React from "react";

const LoginService = {
    login: async function (credentials) {
        const config = {
            headers: {
                'Content-type': 'application/json'
            },
        }
        try {
            const res = await axios.post(
                constants.url.BASE_URL + "login",
                JSON.stringify(credentials),
                config,
            );

            return res.headers.authorization;
        } catch (err) {
            throw err;
        }
    },

    forgotPassword: async function (userName) {
        const config = {
            headers: {
                'userName': userName
            },
        }
        try {
            const res = await axios.post(
                constants.url.BASE_URL + "forgotPassword",
                null,
                config
            );

            return res.headers.authorization;
        } catch (err) {
            throw err;
        }
    },

    changePassword: async function (newPassword, token) {
        const config = {
            headers: {
                'token': token,
                'newPassword': newPassword
            },
        }
        try {
            const res = await axios.post(
                constants.url.BASE_URL + "changePassword",
                null,
                config
            );

            return res.status;
        } catch (err) {
            throw err;
        }
    }
};

export default LoginService;