import { UploadFile } from 'antd/es/upload/interface';
import ScreenContentService from "../components/services/ScreenContentService";

const getBase64 = (file: File | Blob | undefined): Promise<string> => {
    if (!file) return Promise.reject(new Error('no file'));
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
};

const imagePreview = async (file: UploadFile, callback: (params: { image: string }) => void) => {
    const newFile = file;
    if (!newFile.preview) {
        let byteArray = await ScreenContentService.getFullImage(file.uid);
        newFile.preview = byteArray;
    }
    callback({
        image: newFile.preview,
    });
};

export { imagePreview };