import React, {useEffect, useState} from 'react';
import {Table, Row, Col, Button, Typography, Checkbox, Input, Space} from 'antd';
import {useHistory} from 'react-router';
import Highlighter from 'react-highlight-words';
import {SearchOutlined} from '@ant-design/icons';
import NrScreens from "./nrScreens";
import AccountService from "../services/AccountService";
import PermissionService from "../services/PermissionService";

const {Title} = Typography;
const Accounts = ({token}) => {
    const history = useHistory();
    const [allData, setAllData] = useState([]);
    const [searchText, setSearchText] = useState([]);
    const [searchedColumn, setSearchedColumn] = useState([]);
    const [showAddAccount, setShowAddAccount] = useState(false);

    useEffect(() => {
        async function fetchData() {
            setShowAddAccount(await PermissionService.hasRole(token, "SU"));

            let accounts = await AccountService.getAccounts(token);
            setAllData(accounts);
        }
        fetchData()
    }, [])

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
            <div style={{padding: 8}}>
                <Input
                    ref={node => {
                        //setSearchInput(node);
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{width: 188, marginBottom: 8, display: 'block'}}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined/>}
                        size="small"
                        style={{width: 90}}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{width: 90}}>
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({closeDropdown: false});
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }
                        }
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}}/>,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                //todo set focus
                //setTimeout(() => searchInput, 100);
            }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = clearFilters => {
        clearFilters();
        setSearchText('');
    };

    const onChangeActive = async (value, record) => {
        console.log(value.target.checked);
        console.log(record.id);
        await AccountService.toggleActive(token, record.id, value.target.checked)
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            ...getColumnSearchProps('name'),
        },
        {
            title: 'VAT',
            dataIndex: 'vat',
            key: 'vat',
            ...getColumnSearchProps('vat'),
        },
        {
            title: 'Street',
            dataIndex: 'street',
            ...getColumnSearchProps('street'),
        },
        {
            title: 'Postal code',
            dataIndex: 'postalcode'
        },
        {
            title: 'City',
            dataIndex: 'city',
            key: 'city',
            ...getColumnSearchProps('city'),
        },
        {
            title: 'Tel nr',
            dataIndex: 'tel'
        },

        {
            title: 'Active',
            dataIndex: 'subscriptionActive',
            key: 'subscriptionActive',
            render: (value, record, rowIndex) => (
                <Checkbox
                    defaultChecked={record.subscriptionActive === 'true'} onChange={(e) => onChangeActive(e, record)}
                />
            )
        },
        {
            title: 'Screens',
            dataIndex: 'screenEntities',
            render: (text, record) => <NrScreens token={token} record={record}/>
        }
    ];

    const handleClick = () => {
        history.push('/addAccount')
    }
    return (
        <div>
            <Row gutter={[40, 0]}>
                <Col span={18}>
                    <Title level={2}>
                        Accounts
                    </Title>
                </Col>
                {showAddAccount && <Col span={6}>
                    <Button onClick={handleClick} block>Add Account</Button>
                </Col>}
            </Row>
            <Row gutter={[40, 0]}>
                <Col span={24}>
                    <Table columns={showAddAccount? columns: columns.filter(col => col.dataIndex !== 'subscriptionActive')} dataSource={allData}/>
                </Col>
            </Row>
        </div>
    );
}

export default Accounts;