import React from 'react';
import {useHistory} from 'react-router';
import {DesktopOutlined} from "@ant-design/icons";

const NrScreens = (props) => {
    const history = useHistory();
    return (
        <div>
            <DesktopOutlined onClick={() => {
                history.push('/screens', {token: props.token, accountId: props.record.id, name: props.record.name})
            }
            }/>
            <span> </span>
            {props.record.screensEntities.length}
        </div>
    );
}
export default NrScreens;

