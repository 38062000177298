import {constants} from "../../config/constants";
import axios from "axios";

const AccountService =  {

    getAccounts: async function (token) {
        const config = {
            headers: {
                'Authorization': token
            },
        }
        try {
            const res = await axios.get(
                constants.url.BASE_URL + "accounts",
                config
            );

            //onSuccess("Ok");
            return res.data;
        } catch (err) {
            throw err;
        }
    },

    addAccount: async function (token, values) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }
        try {
            const res = await axios.put(
                constants.url.BASE_URL + "accounts",
                JSON.stringify({
                    name: values.name,
                    vat: values.vat,
                    street: values.street,
                    postalcode: values.postalCode,
                    city: values.city,
                    tel: values.telNr,
                    email: values.email,
                    subscriptionActive: values.subscriptionActive}),
                config
            );

            //onSuccess("Ok");
            return res.data;
        } catch (err) {
            throw err;
        }
    },

    toggleActive: async function(token, accountId, active) {
        const config = {
            headers: {
                'Authorization': token
            },
        }
        try {
            const res = await axios.put(
                constants.url.BASE_URL + "accounts/toggleActive?accountId=" + accountId + '&active=' + active,
                null,
                config
            );

            //onSuccess("Ok");
            return res.data;
        } catch (err) {
            throw err;
        }
    }
}

export default AccountService;