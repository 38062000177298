import React, {useState} from 'react';
import {
    Row, Col, Typography, Input, InputNumber, Form, Button,
    Radio, message
} from 'antd';
import {useHistory} from 'react-router';
import AccountService from "../services/AccountService";

const {Title} = Typography;
const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};
const AddAccount = ({token}) => {
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const handleSubmit = async (values: any) => {
        setLoading(true);
        try {
            await AccountService.addAccount(token, values);
            setLoading(false);
            message.success("Account created", 3)
            history.push('/accounts');
        } catch (error) {
            setLoading(false);
            message.error(error.response.data, 10);
        }
    }
    return (
        <div>
            <Row gutter={[40, 0]}>
                <Col span={23}>
                    <Title style={{textAlign: 'center'}} level={2}>
                        New Account
                    </Title>
                </Col>
            </Row>
            <Row gutter={[40, 0]}>
                <Col span={18}>
                    <Form {...layout} onFinish={handleSubmit}>
                        <Form.Item name="name" label="Name"
                                   rules={[
                                       {
                                           required: true,
                                           message: 'Please enter account name'
                                       }
                                   ]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item name="vat" label="VAT"
                                   rules={[
                                       {
                                           required: true,
                                           message: 'Please enter VAT number',
                                           //type: 'vat'
                                       }
                                   ]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item name="street" label="Street"
                                   rules={[
                                       {
                                           required: true,
                                           message: 'Please enter street',
                                       }
                                   ]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item name="postalCode" label="Postal code"
                                   rules={[
                                       {
                                           required: true,
                                           message: 'Please enter postal code',
                                           type: 'number'
                                       }
                                   ]}
                        >
                            <InputNumber/>
                        </Form.Item>
                        <Form.Item name="city" label="City"
                                   rules={[
                                       {
                                           required: true,
                                           message: 'Please enter city',
                                           type: 'string'
                                       }
                                   ]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item name="telNr" label="Telephone"
                                   rules={[
                                       {
                                           required: true,
                                           message: 'Please enter telephone',
                                           type: 'number'
                                       }
                                   ]}
                        >
                            <InputNumber/>
                        </Form.Item>
                        <Form.Item name="email" label="Email"
                                   rules={[
                                       {
                                           required: true,
                                           message: 'Please enter email',
                                           type: 'email'
                                       }
                                   ]}
                        >
                            <Input/>
                        </Form.Item>
                            <Form.Item name="subscriptionActive" label="Active"
                                       rules={[
                                           {
                                               required: true,
                                               message: 'Please make a choice',
                                           }
                                       ]}
                            >
                                <Radio.Group>
                                    <Radio value="true">Yes</Radio>
                                    <Radio value="false">No</Radio>
                                </Radio.Group>
                            </Form.Item>

                            <div style={{textAlign: "right"}}>
                                <Button type="primary" loading={loading} htmlType="submit">
                                    Save
                                </Button>{' '}
                                <Button type="danger" htmlType="button" onClick={() => history.push('/accounts')}>
                                    Back
                                </Button>
                            </div>
                    </Form>
                </Col>
            </Row>
        </div>
);
}
export default AddAccount;