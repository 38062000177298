import {constants} from "../../config/constants";
import axios from "axios";
import jwt from "jwt-decode";

const PermissionService =  {

    hasRole: async function (token, role) {
        const jsonToken = jwt(token); // decode your token here
        if (jsonToken.hasOwnProperty(role)) {
            if (jsonToken[role] === 'true') {
                return true;
            }
        }
        return false;
    }
}

export default PermissionService;