import React from 'react';
import { Menu } from 'antd';
import {
    AccountBookOutlined,
    DesktopOutlined,
} from '@ant-design/icons';
import {useHistory}  from 'react-router';
const SideNav = () => {
    const history = useHistory();
    const handleUserClick = () => {
        history.push('/accounts');
    }
    const handleScreensClick = () => {
        history.push('/screens');
    }
    return (
        <div>
            <div style={{height: "32px", background: "rgba(255, 255, 255, 0.2)", margin: "16px"}}></div>
            <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']}>
                <Menu.Item key="1" onClick={handleUserClick}>
                    <AccountBookOutlined />
                    <span> Accounts</span>
                </Menu.Item>
                <Menu.Item key="2" onClick={handleScreensClick}>
                    <DesktopOutlined />
                    <span> Screens</span>
                </Menu.Item>
            </Menu>
        </div>
    );
}
export default SideNav;