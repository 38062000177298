import React, {useEffect, useState} from 'react';
import PicturesGrid from "./components/PicturesGrid";
import defaultFileList from '../../util/defaultFileList';
import {PlusOutlined} from '@ant-design/icons/lib';
import axios from 'axios';
import ScreenContentService from "../services/ScreenContentService";
import {Spin, message} from 'antd';
import {constants} from "../../config/constants";

const Contents = (props) => {
    const [fileList, setFileList] = useState(defaultFileList);
    const [progress, setProgress] = useState(0);
    const [loading, setLoading] = useState(true);

    const uploadButton = (
        <div>
            <PlusOutlined/>
            <div>Upload</div>
        </div>
    );

    useEffect(() => {
        async function fetchData() {
            let fileList = await ScreenContentService.getScreenContent(props.location.state.token, props.location.state.uuid);
            setLoading(false);
            setFileList(fileList);
        }

        fetchData()
    }, [])

    const handleChange = async (newFileList) => {

        var oldList = fileList;
        var newList = newFileList.fileList;

        if (newList.length < oldList.length) {
            let difference = oldList.filter(x => !newList.includes(x));

            await ScreenContentService.deleteContents(props.location.state.token, props.location.state.screenId, difference[0].uid);
            setFileList(newList);

        } else {
            if (newList.length === oldList.length) {
                var bodyJson = [];
                for (let i = 0; i < newList.length; i++) {
                    const fileListElement = newList[i];
                    let bodyJsonElement = {};
                    bodyJsonElement.position = i;
                    bodyJsonElement.uuid = fileListElement.uid;
                    bodyJson.push(bodyJsonElement);
                }

                try {
                    message.info("Saving");
                    await ScreenContentService.changeOrder(props.location.state.token, props.location.state.uuid, bodyJson);
                    setFileList(newList);
                    message.destroy();
                    message.success("Saved", 3)
                } catch (error) {
                    message.error("Error saving" + error, 10);
                }
            }
        }
    }

    const handleRequest = async options => {
        const {onSuccess, onError, file, onProgress} = options;

        const fmData = new FormData();
        const config = {
            onUploadProgress: event => {
                const percent = Math.floor((event.loaded / event.total) * 100);
                setProgress(percent);
                if (percent === 100) {
                    setTimeout(() => setProgress(0), 1000);
                }
                onProgress({percent: (event.loaded / event.total) * 100});
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': props.token
            },
        };
        fmData.append("file", file);
        fmData.append("type", file.type);
        fmData.append("screenId", props.location.state.screenId);
        try {
            const res = await axios.post(
                constants.url.BASE_URL + "contents",
                fmData,
                config
            );

            onSuccess("Ok");
            let fileList = await ScreenContentService.getScreenContent(props.location.state.token, props.location.state.uuid);
            setFileList(fileList);
        } catch (err) {
            onError({err});
        }
    };

    if (loading) {
        return <div><Spin/> Loading...</div>;
    }

    return (
        <div>
            <PicturesGrid
                listType="picture-card"
                fileList={fileList}
                onChange={handleChange}
                customRequest={handleRequest}
                props={props}
            >
                {fileList.length >= 9 ? null : uploadButton}
            </PicturesGrid>
        </div>
    );
}
export default Contents;