import { UploadFile } from 'antd/es/upload/interface';

const defaultFileList: UploadFile[] = [
    {
        uid: '1',
        name: 'image.png',
        status: 'done',
        url: 'https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1594899812010&di=06b2b8b00c8b8389c95447a2562f2143&imgtype=0&src=http%3A%2F%2Fimage.biaobaiju.com%2Fuploads%2F20180830%2F22%2F1535637788-yPpZcnIXbh.jpg',
        size: 1,
        type: 'image/jpeg',
    },
];

export default defaultFileList;