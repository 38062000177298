import axios from "axios";
import {constants} from "../../config/constants";

const ScreenContentService = {

    getScreenContent: async function (token, uuid) {
        const config = {
            headers: {
                'Authorization': token
            },
        }
        try {
            const res = await axios.get(
                constants.url.BASE_URL + "contents?uuid=" + uuid,
                config
            );

            let screenContent = res.data;
            var fileList = [];
            for (let i = 0; i < screenContent.length; i++) {
                const screenContentElement = screenContent[i];
                var fileElement = {
                    uid: screenContentElement.uuid,
                    status: 'done',
                    url: 'data:image/jpeg;base64,' + screenContentElement.lob,
                    size: 3,
                    duration: screenContentElement.duration,
                    fromDate: screenContentElement.fromDate,
                    toDate: screenContentElement.toDate,
                    active: screenContentElement.active
                };
                fileList.push(fileElement);
            }
            return fileList;
        } catch (err) {
            throw err;
        }
    },

    deleteContents: async function(token, screenId, uuid) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }
        try {
            const res = await axios.delete(
                constants.url.BASE_URL + "contents/deleteContents?screenId=" + screenId + '&uuid=' + uuid,
                config
            );

            //onSuccess("Ok");

        } catch (err) {
            //const error = new Error("Some error");
            //onError({ err });
        }
    },

    changeOrder: async function(token, uuid, bodyJson) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }
        try {
            const res = await axios.post(
                constants.url.BASE_URL + "contents/changeOrder?uuid=" + uuid,
                JSON.stringify(bodyJson),
                config
            );
        } catch (err) {
            throw err;
        }
    },

    getFullImage: async function(uuid) {
        try {
            const res = await axios.get(
                constants.url.BASE_URL + "contents/getFullImage?uuid=" + uuid
            );
            let screenContentElement = res.data;
            let url = 'data:image/jpeg;base64,' + screenContentElement.fulllob;
            return url;
        } catch (err) {
            throw err;
        }
    }
}
export default ScreenContentService;