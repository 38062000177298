import React , {useEffect}from 'react';
import './App.css'
import ApplicationRoutes from "./config/ApplicationRoutes";
import Login from "./components/pages/login"
import useToken from "./config/useToken";
import ResetPassword from "./components/pages/resetPassword";

function App() {
    const {token, setToken} = useToken();
    useEffect(() => {
        document.title = "Flexcast Portal"
    }, []);
    if (!token) {
        if (window.location.pathname === '/forgotPassword') {
            return <ResetPassword token={new URL(window.location.href).searchParams.get("token")}/>
        }
        return <Login setToken={setToken}/>
    } else {
        return (
            <ApplicationRoutes token={token}/>
        );
    }
}

export default App;
