const prod = {
    url: {
        BASE_URL: 'https://myapp.herokuapp.com',
    }
}
const dev = {
    url: {
        BASE_URL: 'http://localhost:8080/',
    }
};
export const constants = process.env.NODE_ENV === 'development' ? dev : prod;