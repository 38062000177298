import React, {useEffect, useState} from 'react';
import {Table, Row, Col, Button, Typography} from 'antd';
import {useHistory} from 'react-router';
import {DesktopOutlined} from "@ant-design/icons";
import PermissionService from "../services/PermissionService";

const {Title} = Typography;
const Screens = (props) => {
    let title = 'Screens';
    if (props.history.location.state != null) {
        title = 'Screens of ' + props.history.location.state.name;
    }
    const [showAddAccount, setShowAddAccount] = useState(false);

    const history = useHistory();
    const [allData, setAllData] = useState([]);
    useEffect(() => {
        const getScreens = async () => {
            setShowAddAccount(await PermissionService.hasRole(props.token, "SU"));
            if (props.history.location.state != null)
            {
                const screensFromServer = await fetchScreens(props.history.location.state.accountId)
                setAllData(screensFromServer);
            }
            else {
                const screensFromServer = await fetchScreens()
                setAllData(screensFromServer);
            }
        }

        getScreens()
    }, [])

    // Fetch Tasks
    const fetchScreens = async (accountId) => {
        let accountQuery = '';
        if (accountId != null)
        {
            accountQuery = '?accountId=' + accountId;
        }
        const res = await fetch('http://localhost:8080/screens' + accountQuery, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Accept': '*/*',
                'Authorization': props.token
            },
        })

        const data = await res.json();
        return data
    }
    let columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'UUID',
            dataIndex: 'uuid',
            key: 'uuid',
        },
        {
            title: 'Serial',
            dataIndex: 'serial',
            key: 'serial',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Date in use',
            dataIndex: 'dateInUse',
            key: 'dateInUse',
        },
        {
            title: 'Content',
            render: (text, record) => <DesktopOutlined onClick={() => {
                history.push({
                    pathname: '/content',
                    state: {
                        token: props.token,
                        screenId: record.id,
                        uuid:record.uuid
                    }
                });
            }
            }/>
        }
    ];

    const handleClick = () => {
        history.push('/addScreen')
    }
    return (
        <div>
            <Row gutter={[40, 0]}>
                <Col span={18}>
                    <Title level={2}>
                        {title}
                    </Title>
                </Col>
                {showAddAccount && <Col span={6}>
                    <Button onClick={handleClick} block>Add Screen</Button>
                </Col>}
            </Row>
            <Row gutter={[40, 0]}>
                <Col span={24}>
                    <Table columns={columns.filter(col => col.dataIndex !== 'uuid')} dataSource={allData}/>
                </Col>
            </Row>
        </div>
    );
}

export default Screens;