import { useState } from 'react';
import jwt from 'jwt-decode';

export default function useToken() {
    const getToken = () => {
        const tokenString = sessionStorage.getItem('token');
        const userToken = JSON.parse(tokenString);
        return userToken
    };

    const saveToken = userToken => {
        setToken(userToken);
        const user = jwt(userToken); // decode your token here
        sessionStorage.setItem('token', JSON.stringify(userToken));
        sessionStorage.setItem('user', user.sub);
    };

    const [token, setToken] = useState(getToken());

    return {
        setToken: saveToken,token
    }
}

