import React, {CSSProperties, memo, useState, useEffect} from 'react';
import {arrayMove, SortableContainer, SortableElement, SortEnd} from 'react-sortable-hoc';
import './pictureGrid.css';
import {UploadFile} from 'antd/es/upload/interface';
import {UploadChangeParam} from 'antd/lib/upload';
import {imagePreview} from '../../../util/pictureUtil';
import UploadList from 'antd/es/upload/UploadList';
import {Modal, Upload, DatePicker, InputNumber, Checkbox, Button, message} from 'antd';
import {Props, SortableItemParams, SortableListParams} from './types';
import moment from 'moment';
import axios from "axios";

/*const itemStyle: CSSProperties = {
    width: '350px',
    height: '350px',
    margin: 12,
    cursor: 'grab',
    border: '1px solid',
};*/

const SortableItem = SortableElement((params: SortableItemParams) => {

    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [enabled, setEnabled] = useState('');
    const [duration, setDuration] = useState('');

    useEffect(() => {
        setDuration(params.item.duration);
        setToDate(params.item.toDate);
        setFromDate(params.item.fromDate);
        setEnabled(params.item.enabled);
    }, [])

    const handleCheckbox = async (e) => {
        setEnabled(e.target.checked);
    }

    const handleToDate = async (date, dateString) => {
        setToDate(date);
    }

    const handleFromDate = async (date, dateString) => {
        setFromDate(date);
    }

    const handleDuration = async (value) => {
        setDuration(value);
    }

    const handleDelete = async (value) => {


    }

    const handleSave = async (value) => {
        const key = 'update';
        const config = {
            headers: {
                'Authorization': params.props.props.token,
                'Content-type': 'application/json'
            },
        }
        let data = {
            uuid: params.item.uid,
            duration: duration,
            active: enabled,
            fromDate: fromDate,
            toDate: toDate,
        }
        message.loading('Saving', key);
        try {
            const res = await axios.put(
                "http://localhost:8080/contents",
                data,
                config
            );
            message.success({content: "Saved", key: key, duration: 3});
            return res.data;
        } catch (err) {
            const error = new Error("Some error");
            //onError({err});
        }
    }

    return (
        <div className='sortableItem'>
            <UploadList
                locale={{previewFile: 'Preview', removeFile: 'Remove'}}
                showDownloadIcon={false}
                listType={params.props.listType}
                onPreview={params.onPreview}
                onRemove={params.onRemove}
                items={[params.item]}
            />
            <div className='fields'>
                <div>From: <DatePicker showNow showTime
                                       defaultValue={moment([params.item.fromDate], 'YYYY-MM-DD hh:mm:ss')}
                                       onChange={handleFromDate}/></div>
                <div>To: <DatePicker showNow showTime defaultValue={moment([params.item.toDate], 'YYYY-MM-DD hh:mm:ss')}
                                     onChange={handleToDate}/></div>
                <div>Duration: <InputNumber min={1} max={60} defaultValue={[params.item.duration]}
                                            onChange={handleDuration}/> seconds
                </div>
                <div>Active: <Checkbox onChange={handleCheckbox}/></div>
                <div><Button type="primary" onClick={handleSave}>Save</Button></div>
                <div><Button type="primary" onClick={handleDelete}>Delete</Button></div>
            </div>
        </div>
    )
});


const listStyle: CSSProperties = {
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: '100%',
};
const SortableList = SortableContainer((params: SortableListParams) => {

    return (
        <div style={listStyle}>
            {params.items.map((item, index) => (
                <SortableItem
                    key={`${item.uid}`}
                    index={index}
                    item={item}
                    props={params.props}
                    onPreview={params.onPreview}
                    onRemove={params.onRemove}
                />
            ))}
            <Upload
                {...params.props}
                showUploadList={false}
                onChange={params.onChange}
            >
                {params.props.children}
            </Upload>
        </div>
    );
});

const PicturesGrid: React.FC<Props> = memo(({onChange: onFileChange, ...props}) => {
    const [previewImage, setPreviewImage] = useState('');
    const fileList = props.fileList || [];

    const onSortEnd = ({oldIndex, newIndex}: SortEnd) => {
        onFileChange({fileList: arrayMove(fileList, oldIndex, newIndex)});
    };

    const onChange = ({fileList: newFileList}: UploadChangeParam) => {
        onFileChange({fileList: newFileList});
    };

    const onRemove = (file: UploadFile) => {
        const newFileList = fileList.filter(
            (item) => item.uid !== file.uid
        );
        onFileChange({fileList: newFileList});
    };

    const onPreview = async (file: UploadFile) => {
        await imagePreview(file, ({image}) => {
            setPreviewImage(image);
        });
    };

    return (
        <>
            Versleep de foto's om de volgorde te veranderen
            <SortableList
                distance={1}
                items={fileList}
                onSortEnd={onSortEnd}
                axis="xy"
                helperClass="SortableHelper"
                props={props}
                onChange={onChange}
                onRemove={onRemove}
                onPreview={onPreview}
            />
            <Modal
                visible={!!previewImage}
                footer={null}
                onCancel={() => setPreviewImage('')}
                bodyStyle={{padding: 0}}
            >
                <img style={{width: '100%'}} alt="" src={previewImage}/>
            </Modal>
        </>
    );
});

export default PicturesGrid;