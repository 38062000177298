import React, {useState} from 'react';
import '../../Login.css';
import PropTypes from 'prop-types';
import {Form, Input, Button, Checkbox, Modal} from 'antd';
import {UserOutlined, LockOutlined} from '@ant-design/icons';
import LoginService from "../services/LoginService";
import {useTranslation} from "react-i18next";

export default function Login({setToken}) {

    const [errorMessage, setErrorMessage] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showForgot, setShowForgot] = useState(false);
    const {t, i18n} = useTranslation('common');

    const showForgotPassword = () => {
        setShowForgot(true);
    }

    const handleCancel = () => {
        setShowForgot(false);
    }

    const handleOk = async (values: any) => {
        const username = values.userName;

        try {
            await LoginService.forgotPassword(username);
            setShowForgot(false);
            setErrorMessage('If this email address is known, a reset email has been sent, please check your email and follow instructions');
            showError(true);
        } catch (err) {
            if (err.response) {
                if (err.response.status === 403) {
                }
            }
            //message.error("Error logging in" + err, 10);
        }
    }

    const onChange = () => {
        setShowError(false);
    }

    const onFinish = async (values: any) => {
        const username = values.username;
        const password = values.password;
        try {
            let token = await LoginService.login({
                username,
                password
            });
            setShowError(false);
            setToken(token);
        } catch (err) {
            if (err.response) {
                if (err.response.status === 403) {
                    setErrorMessage('Wrong email or password');
                    setShowError(true);
                }
            }
            //message.error("Error logging in" + err, 10);
        }
    };
    const [form] = Form.useForm();
    return (

        <div className="auth-wrapper">
            <div className="auth-inner">
                <h3>{t('login.title')}</h3>
                <Form
                    name="normal_login"
                    className="login-form"
                    initialValues={{remember: true}}
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="username"
                        rules={[{required: true, message: 'Email required', type: "email"}]}
                    >
                        <Input prefix={<UserOutlined className="site-form-item-icon"/>} placeholder="Email"
                               onChange={onChange}/>
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[{required: true, message: 'Password required'}]}
                    >
                        <Input
                            prefix={<LockOutlined className="site-form-item-icon"/>}
                            type="password"
                            placeholder="Password"
                            onChange={onChange}
                        />
                    </Form.Item>
                    {showError && <div className='wrong_password'>{errorMessage}</div>}
                    <Form.Item>
                        <Form.Item name="remember" valuePropName="checked" noStyle>
                            <Checkbox>Remember me</Checkbox>
                        </Form.Item>

                        <a className="login-form-forgot" href="#" onClick={showForgotPassword}>
                            Forgot password
                        </a>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="login-form-button">
                            Log in
                        </Button>
                    </Form.Item>
                </Form>
            </div>
            <Modal title="Forgot password" visible={showForgot} onCancel={handleCancel}
                   onOk={() => {
                       form
                           .validateFields()
                           .then(values => {
                               form.resetFields();
                               handleOk(values);
                           })
                           .catch(info => {
                           });
                    }}>
                <p>An email will be sent with a "change password" link:</p>
                <Form
                    form={form}
                    name="normal_login"
                    className="login-form"
                    initialValues={{remember: false}}
                >
                    <Form.Item
                        name="userName"
                        rules={[{required: true, message: 'Email required', type: "email"}]}
                    >
                        <Input prefix={<UserOutlined className="site-form-item-icon"/>} placeholder="Email"/>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

Login.propTypes = {
    setToken: PropTypes.func.isRequired
}
