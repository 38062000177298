import React, {useState} from 'react';
import {Button, Form } from "antd";
import {LockOutlined} from "@ant-design/icons";
import {PasswordInput} from "antd-password-input-strength";
import LoginService from "../services/LoginService";

export default function ResetPassword({token}) {

    const [errorMessage, setErrorMessage] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showConf, setShowConf] = useState(false);

    const onFinish = async (values: any) => {
        let password1 = values.newPassword1
        let password2 = values.newPassword2

        if (password1 !== password2) {
            setErrorMessage("Passwords do not match");
            setShowError(true);
        }
        else {
            try {
                await LoginService.changePassword(password1, token);
                setErrorMessage('Your password had been changed');
                setShowConf(true);
            } catch (err) {
                if (err.response) {
                    if (err.response.status === 403) {
                    }
                }
                //message.error("Error logging in" + err, 10);
            }
        }
    }

    return (
        <div className="auth-wrapper">
            <div className="auth-inner">
                <h3>Enter new password:</h3>
                <Form
                    name="normal_login"
                    className="login-form"
                    initialValues={{remember: true}}
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="newPassword1"
                        rules={[{required: true, message: 'Enter new password'}]}
                    >
                        <PasswordInput
                            prefix={<LockOutlined className="site-form-item-icon"/>}
                            type="password"
                            placeholder="Enter new password"
                        />
                    </Form.Item>
                    <Form.Item
                        name="newPassword2"
                        rules={[{required: true, message: 'Re-Enter new password'}]}
                    >
                        <PasswordInput
                            prefix={<LockOutlined className="site-form-item-icon"/>}
                            type="password"
                            placeholder="Re-enter new password"
                        />
                    </Form.Item>
                    {showError && <div className='wrong_password'>{errorMessage}</div>}
                    {showConf && <div className='password_changed'>{errorMessage} <br></br>You can <a href="http://localhost:3000">login again</a> </div>}
                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="login-form-button">
                            Change password
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )

}