import React, {useState, useEffect} from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import Accounts from "../components/pages/accounts";
import AddAccount from "../components/pages/addAccount";
import SideNav from "../components/layouts/sidebar";
import Screens from "../components/pages/screens";
import Contents from "../components/pages/content";
import { Layout, Avatar, Menu, Dropdown } from 'antd';

import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined,
    LogoutOutlined
} from '@ant-design/icons';
const { Header, Sider, Content} = Layout;
const ApplicationRoutes = ({token}) => {
    const [collapse, setCollapse] = useState(false);
    useEffect(() => {
        window.innerWidth <= 760 ? setCollapse(true) : setCollapse(false);
    }, []);
    const handleToggle = (event: any) => {
        event.preventDefault();
        collapse ? setCollapse(false) : setCollapse(true);
    }

    // Our custom 404 component (normally implemented in ./Page404.jsx)
    const Page404 = () => <h1>Four:oh:four</h1>

    const onClickLogout = () => {
        sessionStorage.clear();
        window.location.reload(true);

    }

    const menu = (
        <Menu onClick={onClickLogout}>
            <Menu.Item key="1">
                <LogoutOutlined/>
                Log out
            </Menu.Item>
        </Menu>
    );

    return (
        <Router>
            <Layout>
                <Sider trigger={null} collapsible collapsed={collapse}>
                    <SideNav />
                </Sider>
                <Layout>
                    <Header className="siteLayoutBackground" style={{padding: 0, background: "#001529"}}>
                        {React.createElement(collapse ? MenuUnfoldOutlined : MenuFoldOutlined, {
                            className: 'trigger',
                            onClick: handleToggle,
                            style: {color: "#fff"}
                        })}
                        <div style={{margin: 10, float: 'right'}}>
                            <Dropdown overlay={menu}>
                                <Avatar style={{ backgroundColor: '#87d068'}} icon={<UserOutlined />}/>
                            </Dropdown>
                        </div>
                    </Header>
                    <Content style={{margin: '24px 16px', padding: 24, minHeight: "calc(100vh - 114px)", background: "#fff"}}>
                        <Switch>
                            <Route path="/accounts" render={(props) => <Accounts {...props} token={token} />} />
                            <Route path="/addAccount" render={(props) => <AddAccount {...props} token={token} />} />
                            <Route path="/screens" render={(props) => <Screens {...props} token={token} />} />
                            <Route path="/content" render={(props) => <Contents  {...props} token={token} />} />
                            <Redirect to="/accounts" from="/" />
                            <Route component={Page404} />
                        </Switch>
                    </Content>
                </Layout>
            </Layout>
        </Router>
    );
}
export default ApplicationRoutes;